import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { APIURL } from '@/components/Utility/APIBase'
import CommonFunctions, { countryList } from '@/components/Utility/Common'
import BaseFunctions, { defaultClubManagerGroupId } from '@/components/Utility/Base'
import ByPostDataService from '@/services/ByPostDataService'
import { Products } from '@/services/ProductsDataService'
import OrdersDataService, { Orders } from '@/services/OrdersDataService'
import { Competitions } from '@/services/CompetitionsDataService'
import MembersDataService from '@/services/MembersDataService'
import { History } from '@/services/HistoryDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { Events } from '@/services/EventsDataService'
import ByPost from '@/types/ByPost'
import SysCart, { SysClubLicenseRenewal, SysLicenseRenewal, SysEventSignUp } from '@/types/SysCart'
import SysProduct from '@/types/SysProduct'
import SysCompetition, { SysCompetitionRegistration } from '@/types/SysCompetition'
import SysMember from '@/types/SysMember'
import axios from 'axios'
import bambora from '@bambora/checkout-sdk-web'
import SysOrder, { SysOrderStorage } from '@/types/SysOrder'
import SysClub from '@/types/SysClub'

type orderDataType = { name: string; address: string; zipCode: string; city: string; telephone: string; email: string; comment: string; club: string; }
type dataReturnType = { error: any; orderData: orderDataType; products: any; }

export default class Checkout extends Vue {
  private languageValue = 1
  testInvoice = false // If true disables Bambora payment, and goes directly to the the final part of order execution
  testPayment = false // If true disables the final parts of the order execution
  error: any = null
  private cartItems: SysCart[] = []
  private products: SysProduct[] = []
  /* private */ orderData: orderDataType = { name: '', address: '', zipCode: '', city: '', telephone: '', email: '', comment: '', club: '' }
  countryListOptions = countryList.map(function (item) { return item.name })
  countryStringValue = 'Denmark'
  phoneCode = '+45'
  private inhibitUpdateCityInfo = false
  private cityZipCodeInputFocus = 2
  private cityZipCode: ByPost[] = []
  private currentByPostId = 0
  /* private */ submitted = false
  private currentOrderNumber = 0
  nowDate = new Date().toISOString()
  // paymentOptions: string[] = ['VISA/dankort', 'Faktura']
  // invoicePayment = false
  // paymentValue = 'VISA/dankort'
  clubPurchase = false
  member = {} as SysMember
  canMakeClubPurchase = false
  clubPurchaseInfo = { id: 0, clubCredits: 0, useOfCredits: 0, name: '' }
  // paymentOptions: { name: string, value: number}[] = [{ name: 'Mobilepay', value: 1 }, { name: 'VISA/dankort', value: 2 }, { name: 'Paypal', value: 3 }, { name: 'Faktura', value: 4 }]
  // paymentValue = { name: 'VISA/dankort', value: 2 }
  intervalTimer = 0
  bamboraModal = false
  // bamboraSession: HTMLElement | null = null
  bamboraSession: string | null = null
  private mercNbr = 6330592
  private currentOrderid = 0
  checkCount = 0

  $Message: any
  data (): dataReturnType {
    return {
      error: this.error,
      orderData: this.orderData,
      products: this.products
    }
  }

  @Watch('clubPurchaseInfo.useOfCredits', { immediate: true, deep: true })
  onCreditChange () : void {
    if (this.clubPurchaseInfo.useOfCredits < 0) {
      this.clubPurchaseInfo.useOfCredits = 0
    }
    this.calcTotal()
  }

  @Watch('clubPurchase')
  async onClubPurchaseChange (newVal: boolean) : Promise<void> {
    // console.log('[onClubPurchaseChange()] member = ' + JSON.stringify(this.member))

    if (newVal && this.member.klubber_id !== null && this.member.user_id.usrgroup === 3) {
      // Fetches the clubs
      ClubsDataService.get(Number(this.member.klubber_id.id).toString())
        .then((response) => {
          const tempClub = response.data as SysClub

          this.clubPurchaseInfo = {
            id: Number(tempClub.id),
            clubCredits: tempClub.klubber_saldo,
            useOfCredits: 0,
            name: tempClub.klubber_klubnavn
          }
          console.log('[onClubPurchaseChange()] clubPurchaseInfo = ' + JSON.stringify(this.clubPurchaseInfo))
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      this.clubPurchaseInfo = { id: 0, clubCredits: 0, useOfCredits: 0, name: '' }
    }
  }

  @Watch('countryStringValue')
  onCountryStringValueChange (newVal: string) : void {
    this.phoneCode = countryList.find(x => x.name === newVal)?.phoneCode || '+'
  }

  @Watch('zipCode')
  onZipCodeChange (zipCodeValue: string) : void {
    if (this.cityZipCodeInputFocus === 2 && this.inhibitUpdateCityInfo === false && zipCodeValue.trim().length >= 1 && zipCodeValue.trim().length <= 4 && !isNaN(Number(zipCodeValue))) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(zipCodeValue)
    }
  }

  @Watch('city')
  onCityChange (cityValue: string) : void {
    if (this.cityZipCodeInputFocus === 1 && cityValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(cityValue)
    }
  }

  @Watch('cityZipCodeInputFocus')
  onCityZipCodeFocusChange (inputFocus: number) : void {
    if (inputFocus === 1) {
      if (this.orderData.zipCode.length >= 1 && this.orderData.zipCode.length <= 4 && !isNaN(Number(this.orderData.zipCode))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.orderData.zipCode)
      }
    } else if (inputFocus === 2) {
      if (this.orderData.city !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.orderData.city)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.orderData.zipCode)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.cityZipCode = response.data

          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.orderData.zipCode = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && postalCodeData.trim().length >= 3 && postalCodeData.trim().length <= 4 && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.cityZipCode = response.data
          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.orderData.city = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public haveItemBeenTwoDaysInCart (dateString: string) : boolean {
    const d = new Date(this.nowDate)

    const dateTwoDaysAgo = d.setDate(d.getDate() - 2)

    return new Date(dateString).toISOString().split('T')[0] < new Date(dateTwoDaysAgo).toISOString().split('T')[0]
  }

  public haveItemExpired (dateString: string) : boolean {
    return new Date(this.nowDate).toISOString().split('T')[0] > new Date(dateString).toISOString().split('T')[0]
  }

  public addTwoDays (dateString: string) : string {
    const d = new Date(dateString)

    const twoDaysFromToday = d.setDate(d.getDate() - 2)
    console.log(new Date(twoDaysFromToday).toISOString().split('T')[0])

    return new Date(twoDaysFromToday).toISOString().split('T')[0]
  }

  public calcTotal () : number {
    const sum = this.cartItems.reduce((acc, obj) => {
      return acc + (obj.price * obj.quantity)
    }, 0)

    return sum - this.clubPurchaseInfo.useOfCredits
  }

  public async OpenBambora (orderNr: any, totalAmount: any) {
    // const response = await axios.put(APIURL + '/epgate', {
    //   data: {
    //     order_id: orderNr,
    //     order_amount: totalAmount
    //   }
    // })
    this.bamboraModal = true
    // this.bamboraSession = response.data
    this.bamboraSession = '<pre>TEST</pre>'

    // const sessionToken = response.data.token
    // console.log('[OpenBambora] response = ' + JSON.stringify(response))
    // const checkout = new bambora.ModalCheckout(sessionToken)
    // checkout.show()
    this.checkCount = 0
    this.intervalTimer = setInterval(() => {
      this.checkOrderStatus(Number(orderNr))
    }, 16000)
  }

  // Method that
  public async checkOrderStatus (orderId: number) : Promise<void> {
    axios.get(`${APIURL}/eksternservicepgaccepts/?orderid=${orderId}`)
      .then((response) => {
        console.log(response.data)
        // If the payment goes through, the user is redericted to the CheckoutSuccess page, which automatically handles the rest
        if (response.data !== undefined && response.data !== null && response.data.length > 0) {
          // It is also possible to put code here if the transaction have been declined
          if (this.testPayment) {
            // If true prevent the last part of the order to be finished
            return
          }
          clearInterval(this.intervalTimer)
          this.bamboraModal = false
          this.bamboraSession = null
          this.$router.push({ name: 'CheckoutSuccess' })
        }
      })
      .catch((err) => {
        console.log(err)
      })
    axios.get(`${APIURL}/eksternservicepgcancels/?orderid=${orderId}`)
      .then((response) => {
        console.log(response.data)

        this.checkCount++

        if ((response.data !== undefined && response.data !== null && response.data.length > 0) || this.checkCount > 84) {
          clearInterval(this.intervalTimer)
          this.bamboraModal = false
          this.bamboraSession = null
          const updateOrder = {
            ordre_status: 'annulleret'
          }

          Orders.OrdersDataService.update(orderId.toString(), updateOrder)
            .then((response) => {
              console.log(response.data)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async createNewOrder () : Promise<void> {
    const disableOrderCreation = false

    // Perform a basic check to see if all fields have been filled in with valid data.
    // if (this.orderData.name.trim() === '' || this.orderData.address.trim() === '' || this.orderData.zipCode.trim() === '' || Number.isNaN(this.orderData.zipCode) || this.orderData.telephone.trim() === '' || !BaseFunctions.isEmail(this.orderData.email)) {
    if (this.orderData.name.trim() === '' || this.orderData.address.trim() === '' || this.orderData.telephone.trim() === '' || !BaseFunctions.isEmail(this.orderData.email)) {
      this.$Message.danger({ text: (this.languageValue === 1 ? 'Fejl: Et eller flere felter er ikke udfyldt korrekt' : 'Error: One or more fields are not filled in correctly') })
      return
    }
    // Check if the cart have items.
    if (this.cartItems.length === 0) {
      this.$Message.danger({ text: 'Fejl: Kurven er tom' })
      return
    }

    // Fetch the userData from localStorage.
    const userData = localStorage.getItem('user')
    let userId = 1
    if (userData !== null) {
      const userObj = JSON.parse(userData)
      userId = userObj.id
    }
    console.log(userId)

    // Temporary disable for guests and most users.
    if (disableOrderCreation && (userId === undefined || userId === null || Number(userId) >= 100 || Number(userId) === 1)) {
      return
    }

    const orderExtraDataArr: any[] = [] // Used for storing user & member creation data, that will be stored on the order entry of the API.

    const promises = []
    // Fetch the order with the highest "fakturanummer".
    const orderPromise = Orders.OrdersDataService.getAll('ordre_fakturanummer:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
    promises.push(orderPromise)
    // console.log(this.cartItems)
    for (let i = 0; i < this.cartItems.length; i++) {
      const createOrderUnitData = {
        produkt_id: this.cartItems[i].productId,
        ordreenheder_antal: this.cartItems[i].quantity,
        ordreenheder_beskrivelse: this.cartItems[i].productDescription,
        ordreenheder_pris: this.cartItems[i].price
      }
      // console.log(createOrderUnitData)

      // Create order units, that can be added to the final order
      const orderUnitPromise = Orders.OrderUnitsDataService.create(createOrderUnitData)
      promises.push(orderUnitPromise)

      if (this.cartItems[i].orderExtraData !== null) {
        orderExtraDataArr.push(this.cartItems[i].orderExtraData)
      }
    }

    // Resolve all promises
    await Promise.all(promises)
      .then((response) => {
        const tempOrder = response[0].data[0] as SysOrder
        // console.log(response[1].data)

        const orderUnitIds = []

        for (let i = 1; i < response.length; i++) {
          orderUnitIds.push(Number(response[i].data.id))
        }

        const createOrder = {
          ordre_fakturanummer: Number(tempOrder.ordre_fakturanummer) + 1,
          user_id: Number(userId), // The userId based on the user object in localStorage userId 1 = guest
          ordre_kommentar: this.orderData.comment,
          ordre_status: 'afventer',
          ordreenheders_id: orderUnitIds,
          ordre_faktura: null, // Pdfs will be generated server side, and appended to the entry,
          ordre_navn: this.orderData.name,
          ordre_adresse: this.orderData.address,
          ordre_postnummer: this.orderData.zipCode.toString(),
          ordre_by: this.orderData.city,
          ordre_telefon: this.phoneCode + '-' + this.orderData.telephone.toString(),
          ordre_email: this.orderData.email,
          ordre_land: this.countryStringValue,
          ordre_total: this.calcTotal(),
          ordre_ekstra: (orderExtraDataArr.length >= 1 ? orderExtraDataArr : null)
        }

        Orders.OrdersDataService.create(createOrder)
          .then((response) => {
            const tempOrder = response.data as SysOrder
            // Stores the OrderId, and uses Bamboa payment gate. If the payment is successful, the user is redirected to a new page
            // that handles the registration, and sets the order as "godkendt"
            const orderStorage = { orderId: tempOrder.id, clubPurchase: this.clubPurchase, timeStamp: (Date.now() / 1000), clubId: this.clubPurchaseInfo.id } as SysOrderStorage

            localStorage.setItem('order', JSON.stringify(orderStorage))

            // If it is not an invoice, then open the Payment Window (for credit cards, mobilepay, paypal).
            if (!this.testInvoice) {
              this.currentOrderid = Number(tempOrder.id)
              // console.log('currentOrderid = ' + this.currentOrderid.toString())
              this.OpenBambora(tempOrder.id, this.calcTotal())
            }

            // this.finishTransaction() // This function is only a temp fix

            if (this.clubPurchase && this.clubPurchaseInfo.useOfCredits > 0 && this.clubPurchaseInfo.useOfCredits <= this.clubPurchaseInfo.clubCredits) {
              // FIXME: This should be done on server side.
              const newTotal = this.clubPurchaseInfo.clubCredits - this.clubPurchaseInfo.useOfCredits
              const updateClubCredits = {
                klubber_saldo: newTotal
              }

              ClubsDataService.update(this.clubPurchaseInfo.id.toString(), updateClubCredits)
                .then((response) => {
                  console.log(response.data)
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async finishTransaction () : Promise<void> {
    const orderString = localStorage.getItem('order')
    const cartString = localStorage.getItem('cart')

    if (orderString === null) {
      return
    }
    const orderObj = JSON.parse(orderString) as SysOrderStorage
    const timeInSec = (Date.now() / 1000)

    if (cartString === null || (timeInSec - orderObj.timeStamp) > 3600) {
      // If the cart is empty, something have gone wrong
      // The same is the case, if more than hour have elapsed since communication with Bamboa
      // Updates the order_status to "annulleret" ,removes it from localStorage and return
      const updateOrder = {
        ordre_status: 'annulleret'
      }
      try {
        Orders.OrdersDataService.update(orderObj.toString(), updateOrder)
      } catch (err) {
        console.log(err)
      }
      localStorage.removeItem('order')
      return
    }
    const cartArray = JSON.parse(cartString) as SysCart[]

    const promises = []

    // const updateOrder = {
    //   ordre_status: 'godkendt'
    // }

    // const updateOrderPromise = Orders.OrderUnitsDataService.update(Number(orderObj.orderId).toString(), updateOrder)
    // const updateOrderPromise = Orders.OrdersDataService.update(Number(orderObj.orderId).toString(), updateOrder)
    // promises.push(updateOrderPromise)

    if (orderObj.clubPurchase && orderObj.clubId !== 0) {
      const createClubOrder = {
        klubber_id: orderObj.clubId, // FIXME: Should be the clubId of the member !!FIXED!!
        ordre_id: Number(orderObj.orderId)
      }

      const createClubOrderPromise = Orders.ClubOrderDataService.create(createClubOrder)
      promises.push(createClubOrderPromise)
    }
    // Run through the Cart, and do any API calls if necessary
    for (const item of cartArray) {
      if (item.playerRegistration !== null) {
        const createRegistrationPromise = Competitions.CompetitionMemberRegistrationDataService.create(item.playerRegistration)
        promises.push(createRegistrationPromise)
      }
      if (item.licenseRenewal !== null) {
        const updateMember = {
          medlem_licens_slut: item.licenseRenewal.newLicenseEndDate
        }

        const createMemberHistory = {
          medlemshistorik_handling: 'Licens fornyelse til: ' + CommonFunctions.toDanishDateString(item.licenseRenewal.newLicenseEndDate, 4),
          medlem_id: item.licenseRenewal.memberID
        }
        const updateMemberLicensePromise = MembersDataService.update(item.licenseRenewal.memberID.toString(), updateMember)
        const createMemberHistoryPromise = History.HistoryDataService.create(createMemberHistory)

        promises.push(updateMemberLicensePromise, createMemberHistoryPromise)
      }
      if (item.clubLicenseRenewal !== null) {
        const updateClub = {
          klubber_kontingent_slut: item.clubLicenseRenewal.newLicenseEndDate
        }

        const updateClubPromise = ClubsDataService.update(item.clubLicenseRenewal.clubID.toString(), updateClub)

        promises.push(updateClubPromise)
      }
      if (item.eventSignUp !== null) {
        const createEventRegistration = {
          medlem_id: item.eventSignUp.memberId,
          event_id: item.eventSignUp.eventId
        }

        const createEventRegistrationPromise = Events.EventRegistrationDataService.create(createEventRegistration)

        promises.push(createEventRegistrationPromise)
      }

      await Promise.all(promises)
        .then((response) => {
          for (const item of response) {
            console.log(item.data)
          }
          // this.finished = true
          localStorage.removeItem('order')
          localStorage.removeItem('cart')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  public createOrder () : boolean {
    const disableOrderCreation = false

    if (disableOrderCreation || !(this.orderData.name.trim().length > 1) ||
      !(this.orderData.address.trim().length > 1) ||
      !(this.countryStringValue !== '') ||
      /* isNaN(Number(this.orderData.zipCode)) || */
      !(this.orderData.city.trim().length > 1) ||
      !(this.orderData.telephone.toString().trim().length > 7) ||
      !BaseFunctions.isEmail(this.orderData.email)) {
      this.$Message.danger({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })

      return false
    }

    if (this.cartItems.length === 0) {
      this.$Message.danger({ text: 'Fejl der skal være noget i kurven før du kan betale for det' })

      return false
    } else {
      const orderUnitIds: any = []

      const asyncCreateOrderUnits = async (anyParams: any) => {
        await Orders.OrderUnitsDataService.create(anyParams)
          .then((response) => {
            console.log(response.data)
            orderUnitIds.push(response.data.id)
          })
          .catch((err) => {
            this.error = err
          })
      }

      // Fetching the current order number with the highest value.
      Orders.OrdersDataService.getAll('ordre_fakturanummer:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
        .then((response) => {
          this.currentOrderNumber = response.data[0].ordre_fakturanummer

          // Create Order units.
          const promises = []
          for (let i = 0; i < this.cartItems.length; i++) {
            const createOrderUnitData = {
              produkt_id: this.cartItems[i].productId,
              ordreenheder_antal: this.cartItems[i].quantity,
              ordreenheder_beskrivelse: this.cartItems[i].productDescription,
              ordreenheder_pris: this.cartItems[i].price
            }
            // console.log(createOrderUnitData)

            promises.push(asyncCreateOrderUnits(createOrderUnitData))
          }
          Promise.all(promises)
            .then((response) => {
              console.log(JSON.stringify(response))

              const createOrderData = {
                ordre_fakturanummer: this.currentOrderNumber + 1, // Or whatever number DDU uses for internal tracking.
                user_id: 3, // Should be the current logged in User. How should we handle if someone wants to pay without being logged in?
                ordre_kommentar: this.orderData.comment,
                ordre_status: 'godkendt', // Used for tracking the status should be "Afventer", and chaging to "Godkendt" upon a successful response from payment gateway.
                ordre_transaktion: this.currentOrderNumber + 1, // Should be information about the transaction.
                ordreenheders_id: orderUnitIds,
                ordre_faktura: null, // Any pdf generated by the ordre.
                ordre_navn: this.orderData.name,
                ordre_adresse: this.orderData.address,
                ordre_postnummer: this.orderData.zipCode.toString(),
                ordre_by: this.orderData.city,
                ordre_telefon: this.phoneCode + '-' + this.orderData.telephone.toString(),
                ordre_email: this.orderData.email,
                ordre_land: this.countryStringValue,
                ordre_total: this.calcTotal()
              }

              Orders.OrdersDataService.create(createOrderData)
                .then((response) => {
                  console.log(response.data)
                  const orderId = response.data.id
                  if (this.clubPurchase) {
                    const createClubOrder = {
                      klubber_id: this.clubPurchaseInfo.id,
                      ordre_id: orderId
                    }
                    Orders.ClubOrderDataService.create(createClubOrder)
                      .then((response) => {
                        console.log('Club Order Created: ' + response.statusText)
                      })
                      .catch((err) => {
                        console.error(err)
                      })
                  }

                  // Upon succesful creating a new Order, register the player or players, not in place.
                  // Ideally this should take place upon receiving a a "ordre_status=godkendt".
                  for (const item of this.cartItems) {
                    if (item.playerRegistration !== null) {
                      this.playerCompetitionRegistration(item.playerRegistration)
                    }
                    if (item.licenseRenewal !== null) {
                      this.renewPlayerLicense(item.licenseRenewal)
                    }
                    if (item.clubLicenseRenewal !== null) {
                      this.renewClubLicense(item.clubLicenseRenewal)
                    }
                    if (item.eventSignUp !== null) {
                      this.eventRegistration(item.eventSignUp)
                    }
                  }

                  this.$Message.success({ text: 'Vi har modtaget din ordre' })
                  localStorage.removeItem('cart')
                  this.retrieveCart()

                  return true
                })
                .catch((err) => {
                  this.error = err
                })
            })
            .catch((err) => {
              this.error = err
            })
        })
        .catch((err) => {
          this.error = err
        })
    }

    return false
  }

  public retrieveCart () : void {
    // First get date time from reliable source.
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        const tempNowDate = ((response.data).trim().length > 9 ? Date.parse((response.data).trim()) : this.nowDate)
        this.nowDate = new Date(tempNowDate).toISOString()
        console.log(this.nowDate)
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // If there is not a local item named cart create one.
        if (!localStorage.getItem('cart')) {
          localStorage.setItem('cart', JSON.stringify([]))
        }

        // Parse the cart, and create an array.
        this.cartItems = JSON.parse(localStorage.getItem('cart')!)
        if (this.cartItems.length > 0) {
          // Remove any items, that have expired, or have been in the cart for more than two days.
          this.cartItems = this.cartItems.filter((element) => { return ((element.expirationDate === null && !this.haveItemBeenTwoDaysInCart(element.dateInCart)) || (element.expirationDate !== null && !this.haveItemExpired(element.expirationDate))) })
          localStorage.setItem('cart', JSON.stringify(this.cartItems))
          console.log(this.cartItems)
        }
        if (this.cartItems.length > 0) {
          // If there is still products in the cart fetching the producs from the IP.
          let productParam = ''
          let productIdArray: number[] = []
          for (const objItem of this.cartItems) {
            if (objItem.productId !== 0 || objItem.productId !== null || objItem.productId !== undefined) {
              productIdArray.push(objItem.productId)
            }
          }

          // Eliminate duplicates from the array.
          productIdArray = Array.from(new Set(productIdArray))

          for (const item of productIdArray) {
            productParam += '&id=' + item.toString()
          }
          productParam = productParam.substring(1)

          console.log('Fetching products with ids: ' + JSON.stringify(productIdArray))

          Products.ProductsDataService.getAll('', null, productParam)
            .then((response) => {
              this.products = response.data
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
  }

  public playerCompetitionRegistration (registrationParam: any) : void {
    Competitions.CompetitionMemberRegistrationDataService.create(registrationParam)
      .then((response) => {
        console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public eventRegistration (registration: SysEventSignUp) : void {
    const createNewRegistration = {
      medlem_id: registration.memberId,
      event_id: registration.eventId
    }

    Events.EventRegistrationDataService.create(createNewRegistration)
      .then((response) => {
        console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public renewPlayerLicense (renewalParam: SysLicenseRenewal) : void {
    // Updates the members license date, and then makes a entry in the member history.
    const updateMember = {
      medlem_licens_slut: renewalParam.newLicenseEndDate
    }

    MembersDataService.update(renewalParam.memberID.toString(), updateMember)
      .then((response) => {
        console.log(response.data)

        const createMemberHistory = {
          medlemshistorik_handling: 'Licens fornyelse til: ' + this.toDanishDateString(renewalParam.newLicenseEndDate),
          medlem_id: renewalParam.memberID
        }

        History.HistoryDataService.create(createMemberHistory)
          .then((response) => {
            console.log(response.data)
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public renewClubLicense (renewalParam: SysClubLicenseRenewal) : void {
    // Updates the club license date.
    const updateClub = {
      klubber_kontingent_slut: renewalParam.newLicenseEndDate
    }

    ClubsDataService.update(renewalParam.clubID.toString(), updateClub)
      .then((response) => {
        console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public toDanishDateString (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 4)
  }

  public async retrieveUserData () : Promise<void> {
    this.canMakeClubPurchase = false
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    // if (userDataObject === null || userDataObject.id === 1) {
    if (userDataObject === null) {
      this.$Message.warning({ text: 'Fejl: Forsøg at log ind igen' })
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        const tempMembers = response.data as SysMember[]
        if (tempMembers.length !== 1) {
          return
        }

        this.member = tempMembers[0] as SysMember
        this.orderData = {
          name: this.member.user_id.firstname + ' ' + this.member.user_id.lastname,
          address: this.member.medlem_vejnummer,
          zipCode: this.member.bypost_id === null ? '' : this.member.bypost_id.bypost_postnummer.toString(),
          city: this.member.bypost_id === null ? '' : this.member.bypost_id.bypost_by,
          telephone: this.member.user_id.phone,
          email: this.member.user_id.email,
          comment: '',
          club: this.member.klubber_id === null ? '' : this.member.klubber_id.klubber_klubnavn
        }

        if (this.member.klubber_id !== null && this.member.user_id.usrgroup === defaultClubManagerGroupId) {
          this.canMakeClubPurchase = true
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    // If the user are logged in, attempt to fetch the users data.
    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && (loginType === true.toString() || loginType === false.toString()) && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null) {
      console.log('[Checkout::mounted()] User is logged in.')
      this.retrieveUserData()
    }
    this.retrieveCart()
  }
}
